import { computed, Signal } from '@angular/core';
import { Action, MemoizedSelector, Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

export const enum CallStateType {
    INITIAL,
    LOADING,
    SUCCESS,
    ERROR,
}

export const initialStateResult: ResultStateInitial = {
    result: null,
    state: CallStateType.INITIAL,
};

export function stateLoading<T>(result: T | null, requestId: string | null = null): ResultStateLoading<T | null> {
    return {
        result,
        state: CallStateType.LOADING,
        requestId,
    };
}

export function stateSuccess<T>(result: T, requestId: string | null = null): ResultStateSuccess<T> {
    return {
        result,
        state: CallStateType.SUCCESS,
        requestId: requestId ?? (hasIdProperty(result) ? result.id : null),
    };
}

function hasIdProperty<T>(result: T): result is T & { id: string } {
    return result != null && !Array.isArray(result) && typeof result === 'object' && 'id' in result;
}

export function stateError<ErrType, ResultType>(
    error: ErrType,
    result: ResultType | null,
): ResultStateError<ResultType | null, ErrType> {
    return {
        result,
        state: CallStateType.ERROR,
        error,
    };
}

/**
 * Updates {@link currentState} with {@link updatedPartialResult} if currentState contained a result
 */
export function patchStateResult<ResultType, ErrType>(
    currentState: ResultState<ResultType, ErrType>,
    updatedPartialResult: Partial<ResultType>,
): ResultState<ResultType, ErrType> {
    if (currentState.state === CallStateType.INITIAL || !currentState.result) {
        return currentState;
    }
    return { ...currentState, result: { ...currentState.result, ...updatedPartialResult } };
}

export interface ResultStateInitial {
    readonly result: null;
    readonly state: CallStateType.INITIAL;
}

export interface ResultStateLoading<ResultType> {
    readonly result: ResultType | null;
    readonly state: CallStateType.LOADING;
    /**
     * To be used when in combination with dispatchWhenNotLoaded.
     * If this id matches the id of the action that would be dispatched,
     * the action will not be dispatched if the currently loading request is for the same id.
     */
    readonly requestId: string | null;
}

export interface ResultStateSuccess<ResultType> {
    readonly result: ResultType;
    readonly state: CallStateType.SUCCESS;
    readonly requestId: string | null;
}

export interface ResultStateError<ResultType, ErrorType> {
    readonly result: ResultType | null;
    readonly state: CallStateType.ERROR;
    readonly error: ErrorType;
}

export type ResultState<ResultType, ErrorType = string> =
    | ResultStateInitial
    | ResultStateLoading<ResultType | null>
    | ResultStateSuccess<ResultType>
    | ResultStateError<ResultType | null, ErrorType>;

export function selectResult<T extends ResultState<unknown, unknown>>(state: T): T['result'] {
    return state.result;
}

export function selectResultOrEmptyArray<T extends unknown[], U>(state: ResultState<T, U>): T {
    return state.result ?? ([] as unknown as T);
}

export function selectIsStateLoading(s: ResultState<unknown, unknown>): s is ResultStateLoading<unknown> {
    return s.state === CallStateType.LOADING;
}

export function selectIsStateFailed(s: ResultState<unknown, unknown>): s is ResultStateLoading<unknown> {
    return s.state === CallStateType.ERROR;
}

export function selectIsStateSuccess<T>(s: ResultState<T>): s is ResultStateSuccess<T> {
    return s.state === CallStateType.SUCCESS;
}

export function selectStateDone<T>(s: ResultState<T>): s is ResultStateSuccess<T> {
    return selectIsStateSuccess(s) || selectIsStateFailed(s);
}

/**
 * If equalFn returns true, this indicates that the action should not be dispatched because the expected item is already in the store
 */
export async function maybeDispatch<T, U>(
    store: Store<T>, // eslint-disable-line @ngrx/no-typed-global-store
    selector: MemoizedSelector<T, U>,
    equalFn: (element: U) => boolean,
    action: Action,
) {
    const result = await firstValueFrom(store.select(selector));
    if (!equalFn(result)) {
        store.dispatch(action);
    }
}

/**
 * Dispatches `action` based on the value of the result returned by `selector`
 * Used for dispatching actions to fetch data that should only be fetched once when loading
 * a part of the dashboard.
 * Optionally, if the requesting id is different (to use when fetching details of 1 item), it should be dispatched again.
 */
export function dispatchWhenNotLoaded<T, U extends ResultState<unknown, unknown>, ActionType extends Action>(
    // Disable no-typed-global-store otherwise the type cannot be correctly inferred on the 'selector' variable
    store: Store<T>, // eslint-disable-line @ngrx/no-typed-global-store
    selector: MemoizedSelector<T, U>,
    action: ActionType,
    idProperty?: keyof ActionType,
) {
    return maybeDispatch(
        store,
        selector,
        (state: U) => {
            if (state.state === CallStateType.SUCCESS || state.state === CallStateType.LOADING) {
                return state.requestId && idProperty ? state.requestId === action[idProperty] : true;
            }
            return false;
        },
        action,
    );
}

/**
 * Combines multiple loading selectors into one. When one of the selectors emits true, the result will be true.
 */
export function combineLoadingSelectors(
    store: Store,
    ...selectors: MemoizedSelector<object, boolean>[]
): Signal<boolean> {
    const signals = selectors.map((selector) => store.selectSignal(selector));
    return computed(() => signals.some((signal) => signal()));
}
